import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "right-container-margin"
}
const _hoisted_3 = {
  key: 0,
  class: "div-event-module"
}
const _hoisted_4 = {
  key: 1,
  class: "div-event-module"
}
const _hoisted_5 = {
  key: 2,
  class: "div-event-module"
}
const _hoisted_6 = {
  key: 2,
  class: "watchlive border-radius-20"
}
const _hoisted_7 = { class: "div-watchlive-content" }
const _hoisted_8 = { class: "display-flex header-tab" }
const _hoisted_9 = { class: "mainheadingwatchlive primary-heading" }
const _hoisted_10 = { class: "padding-bottom-10" }
const _hoisted_11 = { class: "description" }
const _hoisted_12 = { class: "datepicker-div" }
const _hoisted_13 = { class: "startdate" }
const _hoisted_14 = { class: "enddate" }
const _hoisted_15 = { class: "margin-top-30" }
const _hoisted_16 = { class: "description" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { class: "watchliveoptionstitle" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "footer" }
const _hoisted_21 = { class: "parent-div-buttons" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_Toaster = _resolveComponent("Toaster")!
  const _component_VirtualMeetingCombineAnalytics = _resolveComponent("VirtualMeetingCombineAnalytics")!
  const _component_MuxVideo = _resolveComponent("MuxVideo")!
  const _component_PreRecordedMeetingHighcharts = _resolveComponent("PreRecordedMeetingHighcharts")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_VirtualMeetingDetails = _resolveComponent("VirtualMeetingDetails")!

  return (_openBlock(), _createElementBlock("div", {
    onKeypress: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onMousemove: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onTouchstart: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
  }, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.showToaster)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Toaster, {
            message: _ctx.toasterMessage,
            "default-timeout": _ctx.toasterDefaultTimeout
          }, null, 8, ["message", "default-timeout"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowMeetAppLiveRecordingSessionAnalytics())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.ShowAddVideoSection() && !_ctx.ShowPreRecordedVideoChartSection())
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_VirtualMeetingCombineAnalytics, {
                  "vonage-analytics-for-graph": _ctx.vonageAnalyticslocal,
                  "selected-program-id": _ctx.SelectedItem.Id,
                  "video-detail": _ctx.SelectedItem.VodDetail,
                  "video-on-demand-metrics": _ctx.SelectedItem.VodDetail.VodVideoAnalytics,
                  "stream-type": _ctx.SelectedItem.VodDetail.VodStreamingType,
                  "module-type": _ctx.SelectedVodBase.Moduletype
                }, null, 8, ["vonage-analytics-for-graph", "selected-program-id", "video-detail", "video-on-demand-metrics", "stream-type", "module-type"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.ShowAddVideoSection() && !_ctx.ShowPreRecordedVideoChartSection())
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_MuxVideo, {
                  "selected-vod-base": _ctx.SelectedVodBase,
                  onSetVodDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SetVodDetailId($event))),
                  onVodsaveupdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vodsaveupdate()))
                }, null, 8, ["selected-vod-base"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.ShowPreRecordedVideoChartSection())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_PreRecordedMeetingHighcharts, {
                  "video-detail": _ctx.SelectedItem.VodDetail,
                  "video-on-demand-metrics": _ctx.SelectedItem.VodDetail.VodVideoAnalytics,
                  "stream-type": _ctx.SelectedItem.VodDetail.VodStreamingType
                }, null, 8, ["video-detail", "video-on-demand-metrics", "stream-type"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.WatchLiveTitle")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_ctx.visibleValidationSummary)
                ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                    key: 0,
                    "error-fields": _ctx.validationErrorFields,
                    onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                  }, null, 8, ["error-fields"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("EventSetting.WatchLiveDescription")), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("StartDateAndTime")), 1),
                _createVNode(_component_Datepicker, {
                  date: _ctx.GetStartDate(),
                  "full-day-time-format": _ctx.CheckTimeFormatStartDate(),
                  "id-value": _ctx.startDateIdString,
                  disable: _ctx.DisabledDates(),
                  onSelectedDate: _ctx.SetBrowserStartDateValue
                }, null, 8, ["date", "full-day-time-format", "id-value", "disable", "onSelectedDate"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("EndDateAndTime")), 1),
                _createVNode(_component_Datepicker, {
                  date: _ctx.GetEndDate(),
                  "full-day-time-format": _ctx.CheckTimeFormatEndDate(),
                  "id-value": _ctx.endDateIdString,
                  disable: _ctx.DisabledDates(),
                  mindatetime: _ctx.minDateTime,
                  onSelectedDate: _ctx.SetBrowserEndDateValue
                }, null, 8, ["date", "full-day-time-format", "id-value", "disable", "mindatetime", "onSelectedDate"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.SelectedItem.EnableChat) = $event)),
                  type: "checkbox",
                  class: "checkbox-alignment",
                  disabled: !_ctx.enableChat
                }, null, 8, _hoisted_17), [
                  [_vModelCheckbox, _ctx.SelectedItem.EnableChat]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('WatchLive.EnableChat')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventSetting.WatchLiveOptionsTitle")), 1)
            ]),
            (_ctx.showVirtualMeetingDetails)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_VirtualMeetingDetails, {
                    "selected-item": _ctx.SelectedItem,
                    "selected-vod-base": _ctx.SelectedVodBase,
                    onSelectedMeetingType: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SetSelectedVodBase($event)))
                  }, null, 8, ["selected-item", "selected-vod-base"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "buttonhr" }, [
              _createElementVNode("hr")
            ], -1)),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  disabled: _ctx.isDirty,
                  class: "btn btn-secondary btn-size-default btn-cancelevent cursor-pointer",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Discard()))
                }, _toDisplayString(_ctx.$t("Button.Discard")), 9, _hoisted_22)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  disabled: _ctx.isDirty,
                  class: "btn btn-primary btn-size-default width100 btn-create-event cursor-pointer",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.SaveWatchLive()))
                }, _toDisplayString(_ctx.$t("Button.Save")), 9, _hoisted_23)
              ])
            ])
          ])
        ]))
  ], 32))
}