import { VonageSessionData } from '@/entities/VonageSessionData';
import { VodDetail } from '@/entities/VodDetail';

export class VirtualMeetingBaseModel {
  public static createEmpty(): VirtualMeetingBaseModel {
    return new VirtualMeetingBaseModel();
  }
  constructor(
    public Id: number = 0,
    public ApplicationId: number = 0,
    public ApplicationInstanceId: number = 0,
    public StartDate: Date = new Date(),
    public EndDate: Date = new Date(),
    public VirtualMeetingLink: string = '',
    public VirtualMeetingType: string = '',
    public VirtualMeetingCode: string = '',
    public VirtualMeetingUrl: string = '',
    public HasExternalZoomLink: boolean = false,
    public VirtualMeetingLiveStreamId: number = 0,
    public VirtualMeetingParentType: string = '',
    public Timeoffset: number = 0,
    public EndDateUTC: Date = new Date(),
    public StartDateUTC: Date = new Date(),
    public IsRecordedSession: boolean = false,
    public IsRecordedAutomatically: boolean = false,
    public EnableChat: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VonageSessionData: VonageSessionData = VonageSessionData,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodDetail: VodDetail = VodDetail,
  ) { }
}



